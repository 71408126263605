import Qs from 'qs';
import {useEffect, useState} from 'react';

export function useAnchor() {
    const [anchor, setAnchor] = useState({});

    useEffect(
        () => {
            function handleHashChange() {
                setAnchor(parseAnchorFromURL(window.location.hash));
            }

            window.addEventListener('hashchange', handleHashChange);

            setAnchor(parseAnchorFromURL(window.location.hash));

            return () => {
                window.removeEventListener('hashchange', handleHashChange);
            };
        },
        [],
    );

    return anchor;
}

export function stringifyAnchor(obj) {
    return '#' + Qs.stringify(obj);
}

export function parseQueryStringFromURL(search) {
    return (search && search.length > 1) ? Qs.parse(search.substr(1)) : {};
}

export function parseAnchorFromURL(url) {
    if (!url) {
        return {};
    }

    const anchorIndex = url.indexOf('#');

    if (anchorIndex !== -1) {
        return Qs.parse(url.substr(anchorIndex + 1));
    } else {
        return {};
    }
}

export function replaceAnchor(url, obj) {
    const anchor = Qs.stringify(obj);

    if (!url) {
        return `#${anchor}`;
    }

    const anchorIndex = url.indexOf('#');

    if (anchorIndex !== -1) {
        url = url.substr(0, anchorIndex);
    }

    return `${url}#${anchor}`;
}

export function findIndexByAnchorId(items, anchorId) {
    if (anchorId) {
        for (let i = 0; i < items.length; ++i) {
            if (getAnchorIdFromEntity(items[i]) === anchorId) {
                return i;
            }
        }
    }
}

export function getAnchorIdFromEntity(entity) {
    let anchorName = entity.fields?.anchorId;

    if (anchorName == null) {
        anchorName = entity.fields?.name;
    }

    return anchorName ? getAnchorIdFromString(anchorName) : '';
}

export function getAnchorIdFromString(s) {
    if (s) {
        return s.replace(/\s+/g, '-');
    }
}

export function removeAnchorFromLink(url){
    return url.substring(0, url.indexOf('#'));
}