import React from 'react';
import PropTypes from 'prop-types';
import {YoutubeVideoConstants} from '../../adapters/helpers/Constants';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import dynamic from 'next/dynamic';
const Image = dynamic(() => import('../Image/Image'))
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import DOMPurify from 'isomorphic-dompurify';
const DEFAULT_ASPECT_RATIO = 16/9;

function YoutubeVideo({document, isSmall}) {
    const videoId = document?.fields?.videoId;
    const aspectRatio = document?.fields?.aspectRatio || DEFAULT_ASPECT_RATIO;
    const classNames = document?.fields?.classNames || '';
    const { disclaimerContent, backgroundImage, devicesConfigurations } = document?.fields;
    
    const deviceImgIndex = isSmall ? 1 : 0
    const deviceImg = devicesConfigurations?.[deviceImgIndex]?.fields?.mainAsset || '';
    const videoBlock = devicesConfigurations?.[deviceImgIndex]?.fields?.additionalAssetList || '';

    return (<React.Fragment>
        {!!videoId && !backgroundImage && 
            <div className={classNames}>
                <div className="ob-youtube-video-block" style={{paddingBottom: (100/ aspectRatio).toFixed(3) + YoutubeVideoConstants.percent}}>
                    <iframe title={YoutubeVideoConstants.title} src={`${YoutubeVideoConstants.url}${videoId}`} ></iframe>
                </div>
            </div>
        }

        {!!videoId && !videoBlock && !!backgroundImage && <section className={classNames}>
            <Image image={backgroundImage} >
                <div className="ob-youtube-video-block" style={{paddingBottom: (100/ aspectRatio).toFixed(3) + YoutubeVideoConstants.percent}}>
                    <iframe title={YoutubeVideoConstants.title} src={`${YoutubeVideoConstants.url}${videoId}?rel=0&version=3&autoplay=1&loop=1playlist=kBp8PZA6ghk`} 
                    frameborder="0"
                    allow="autoplay"
                    ></iframe>
                </div>
                <div className="ob-youtube-img">
                    <Image image={deviceImg} />
                </div>
                <p className='description' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(disclaimerContent)}}></p>
            </Image>
        </section>
        }

        {!!videoBlock && videoBlock.length > 0 && !!backgroundImage && <section className={classNames}>
            <Image image={backgroundImage} >
                <div className="ob-youtube-video-block" style={{paddingBottom: (100/ aspectRatio).toFixed(3) + YoutubeVideoConstants.percent}}>
                <VideoPlayer video={videoBlock?.[0]?.fields} />
                </div>
                {classNames === 'dental-ci-secpage-youtube-video' && <p className='description' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(disclaimerContent)}}></p>}
                <div className="ob-youtube-img">
                    <Image image={deviceImg} />
                </div>
                {classNames !== 'dental-ci-secpage-youtube-video' && <p className='description' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(disclaimerContent)}}></p>}
            </Image>
        </section>
        }

    </React.Fragment>)
}

export default mediaQueryHOC(YoutubeVideo)

YoutubeVideo.propTypes = {
    document: PropTypes.object,
    isSmall: PropTypes.bool,
}